import {Component} from '@angular/core';

import {TranslateService} from "@ngx-translate/core";
import {currentBrowserLanguage} from "@modules/translations/translations.config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translation: TranslateService) {
    this.translation.setDefaultLang(currentBrowserLanguage());
  }
}
