export const ErrorsLanguagePartialDefinition = {
  forms: {
    generic: 'Campo non valido',
    required: 'Questo campo è obbligatorio',
    min: 'Il numero è troppo piccolo',
    max: 'Il numero è troppo grande',
    valid: 'Inserisci un indirizzo e mail valido',
    minlength: 'Lunghezza minima 8 caratteri',
    maxlength: 'Lunghezza massima 2 caratteri',
    unique: 'Valore già utilizzato',
    password: 'Password errata',
    passwordsMismatch: 'Le passwords non coincidono',
    isNotUrl: 'Inserisci un URL valida',
    recaptcha: "L'utente potrebbe essere un bot",
  }
};
