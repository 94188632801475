export const AdministrationLanguagePartialDefinition = {
  menu: {
    articles: 'Articoli',
    clubs: 'Società',
    associates: 'Collaborazioni',
    users: 'Utenti',
    partners: 'Partners',
    events: 'Eventi',
    coupon: 'Coupon',
    reporting: 'Reportistica',
    contacts: 'Contatti'
  },
  header: {
    user: 'Sei autenticato come <b>{{name}}</b>',
    admin: 'ADMIN',
    button: {
      myProfile: 'Il mio profilo',
      logout: 'Logout'
    },
    searchBox: {
      search: 'Cerca'
    },
    settings: 'Impostazioni'
  },
  articles: {
    title: 'Articoli',
    create: 'Scrivi',
    edit: 'Modifica',
    upload: 'Carica',
    list: {
      none: 'Nessun articolo pubblicato',
      labels: {
        author: 'Autore',
        body: 'Testo',
        image: 'Immagine',
        title: 'Titolo',
        actions: 'Azioni'
      }
    },
    form: {
      labels: {
        title: 'Titolo',
        text: 'Testo',
        img: 'Immagine',
        facebook: 'Facebook',
        instagram: 'Instagram',
        tiktok: 'TikTok',
        twitter: 'Twitter',
        linkedin: 'Linkedin',
        youTube: 'YouTube'
      },
      placeholders: {
        title: 'Inserisci il titolo',
        text: 'Inserisci il testo',
        img: 'Scegli un immagine',
        facebook: 'Account Facebook',
        instagram: 'Account Instagram',
        tiktok: 'Account TikTok',
        twitter: 'Account Twitter',
        linkedin: 'Account Linkedin',
        youTube: 'Account YouTube'
      },
      actions: {
        publish: 'Pubblica l\'articolo',
        edit: 'Modifica articolo'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica articolo',
        message: 'Articolo modificato correttamente',
        error: 'Errore durante la modifica'
      },
       create: {
         title: 'Crea articolo',
         message: 'Articolo creato correttamente',
         error: 'Errore durante la creazione'
       },
      delete: {
        title: 'Cancella articolo',
        message: 'Articolo cancellato correttamente',
        error: 'Errore durante la cancellazione'
      },
      upload: {
        title: 'Upload immagine',
        message: 'Immagine caricata correttamente',
        errors: {
          title: 'Errore durante l\'upload',
          oversize: 'File troppo grande. Dimensione massima 20Mb'
        }
      }
    },
    modals: {
      delete: {
        title: 'Cancella articolo',
        message: 'Stai per cancellare l\'articolo <b>{{title}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    },
  },
  clubs: {
    title: 'Società',
    create: 'Aggiungi società',
    edit: 'Modifica società',
    upload: 'Carica',
    list: {
      none: 'Nessuna società registrata.',
      labels: {
        accountStatus: {
          label: 'Account',
          PENDING: 'In attesa',
          CONFIRMED: 'Confermato'
        },
        businessName: 'Ragione Sociale',
        vatcode: 'P.Iva/Codice Fiscale',
        contactPerson: 'Referente',
        street: 'Indirizzo',
        website: 'Sito web',
        email: 'E-mail',
        phone: 'Telefono',
        introductionText: 'Presentazione',
        actions: 'Azioni'
      },
      filters: {
        title: 'Filtri',
        labels: {
          resetFilters: 'Reset',
          registrationDate: 'Data di registrazione',
          search: 'Cerca'
        }
      },
      counters: {
        results: 'Risultati:'
      }
    },
    form: {
      labels: {
        businessName: 'Ragione Sociale',
        vatcode: 'P.Iva/Codice Fiscale',
        email: 'E-mail',
        contactPerson: 'Referente',
        phone: 'Telefono',
        password: 'Password',
        'password-confirm': 'Conferma password',
        street: 'Indirizzo',
        streetNumber: 'Civico',
        zipcode: 'CAP',
        district: 'Comune',
        state: 'Provincia',
        introductionText: 'Presentazione',
        website: 'Sito web',
        facebookLink: 'Facebook Link',
        instagramLink: 'Instagram Link',
        twitterLink: 'Twitter Link'
      },
      placeholders: {
        businessName: 'Inserisci ragione sociale',
        vatcode: 'Inserisci p.Iva/codice fiscale',
        email: 'Inserisci e-mail',
        contactPerson: 'Inserisci referente',
        phone: 'Inserisci telefono',
        password: 'Inserisci password',
        'password-confirm': 'Conferma la password',
        street: 'Cerca l\'indirizzo...',
        streetNumber: 'Inserisci il n.civico',
        state: 'Scegli la provincia',
        district: 'Scegli il comune',
        zipcode: 'Inserisci il cap',
        introductionText: 'Inserisci una presentazione',
        website: 'Inserisci il sito web',
        facebookLink: 'Inserisci facebook link',
        instagramLink: 'Inserisci instagram link',
        twitterLink: 'Inserisci Twitter link'
      },
      actions: {
        create: 'Aggiungi società',
        edit: 'Modifica società'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica società',
        message: 'Società <b>{{businessName}}</b> modificata con successo',
        error: 'Errore durante la modifica',
      },
      create: {
        title: 'Crea società',
        message: 'Società <b>{{businessName}}</b> creata con successo',
        error: 'Errore durante la creazione',
      },
      delete: {
        title: 'Cancella società',
        message: 'Società <b>{{businessName}}</b> cancellata con successo',
        error: 'Errore durante la cancellazione'
      },
      upload: {
        logo: {
          title: 'Carica logo',
          message: 'Logo caricato con successo',
          errors: {
            title: 'Errore nella caricazione',
            oversize: 'File troppo grande. Limite massimo consentito 2Mb'
          }
        }
      }
    },
    modals: {
      delete: {
        title: 'Cancella società',
        message: 'Stai per cancellare la società <b>{{businessName}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      },
      loginAs : {
        title: 'Cambia utente',
        message: 'Vuoi loggarti come <b>{{clubName}}</b>?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    }
  },
  associates: {
    title: 'Collaborazioni',
    create: 'Aggiungi collaborazione',
    edit: 'Modifica collaborazione',
    upload: 'Carica',
    list: {
      none: 'Nessuna collaborazione registrata.',
      labels: {
        accountStatus: {
          label: 'Account',
          PENDING: 'In attesa',
          CONFIRMED: 'Confermato'
        },
        businessName: 'Ragione Sociale',
        vatcode: 'P.Iva/Codice Fiscale',
        contactPerson: 'Referente',
        street: 'Indirizzo',
        website: 'Sito web',
        facebookLink: 'Facebook Link',
        instagramLink: 'Instagram Link',
        twitterLink: 'Twitter Link',
        email: 'E-mail',
        phone: 'Telefono',
        introductionText: 'Presentazione',
        actions: 'Azioni'
      },
      filters: {
        title: 'Filtri',
        labels: {
          resetFilters: 'Reset',
          registrationDate: 'Data di registrazione',
          search: 'Cerca'
        }
      },
      counters: {
        results: 'Risultati:'
      }
    },
    form: {
      labels: {
        businessName: 'Ragione Sociale',
        vatcode: 'P.Iva/Codice Fiscale',
        email: 'E-mail',
        contactPerson: 'Referente',
        phone: 'Telefono',
        password: 'Password',
        'password-confirm': 'Conferma password',
        street: 'Indirizzo',
        streetNumber: 'Civico',
        zipcode: 'CAP',
        district: 'Comune',
        state: 'Provincia',
        introductionText: 'Presentazione',
        website: 'Sito web',
        facebookLink: 'Facebook Link',
        instagramLink: 'Instagram Link',
        twitterLink: 'Twitter Link'
      },
      placeholders: {
        businessName: 'Inserisci ragione sociale',
        vatcode: 'Inserisci p.Iva/codice fiscale',
        email: 'Inserisci e-mail',
        contactPerson: 'Inserisci referente',
        phone: 'Inserisci telefono',
        password: 'Inserisci password',
        'password-confirm': 'Conferma la password',
        street: 'Cerca l\'indirizzo...',
        streetNumber: 'Inserisci il n.civico',
        state: 'Scegli la provincia',
        district: 'Scegli il comune',
        zipcode: 'Inserisci il cap',
        introductionText: 'Inserisci una presentazione',
        website: 'Inserisci il sito web',
        facebookLink: 'Inserisci facebook link',
        instagramLink: 'Inserisci instagram link',
        twitterLink: 'Inserisci Twitter link'
      },
      actions: {
        create: 'Aggiungi collaborazione',
        edit: 'Modifica collaborazione'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica collaborazione',
        message: 'Collaborazione con <b>{{businessName}}</b> modificata con successo',
        error: 'Errore durante la modifica',
      },
      create: {
        title: 'Crea collaborazione',
        message: 'Collaborazione con <b>{{businessName}}</b> creata con successo',
        error: 'Errore durante la creazione',
      },
      delete: {
        title: 'Cancella collaborazione',
        message: 'Collaborazione con <b>{{businessName}}</b> cancellata con successo',
        error: 'Errore durante la cancellazione'
      },
      upload: {
        logo: {
          title: 'Carica logo',
          message: 'Logo caricato con successo',
          errors: {
            title: 'Errore nella caricazione',
            oversize: 'File troppo grande. Limite massimo consentito 2Mb'
          }
        }
      }
    },
    modals: {
      delete: {
        title: 'Cancella collaborazione',
        message: 'Stai per cancellare la collaborazione con <b>{{businessName}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      },
      loginAs : {
        title: 'Cambia utente',
        message: 'Vuoi loggarti come <b>{{associateName}}</b>?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    }
  },
  users: {
    title: 'Utenti',
    create: 'Aggiungi',
    edit: 'Modifica',
    import: 'Importa',
    export: 'Esporta',
    list: {
      none: 'Nessun utente registrato',
      labels: {
        name: 'Nome',
        surname: 'Cognome',
        fullName: 'Nome completo',
        taxCode: 'Codice fiscale',
        email: 'E-mail',
        birthDate: 'Data di nascita',
        birthPlace: 'Luogo di nascita',
        street: 'Indirizzo',
        streetNumber: 'N. civico',
        zipcode: 'CAP',
        district: 'Comune',
        state: 'Provincia',
        registrationDate: 'Data di registrazione',
        club: 'Società',
        actions: 'Azioni'
      },
      dropdown: {
        label: 'Ordine',
        fromAToZ: 'Ordine: dalla A alla Z',
        fromZToA: 'Ordine: dalla Z alla A',
        nameFromAToZ: 'Nome dalla A alla Z',
        surnameFromAToZ: 'Cognome dalla A alla Z',
        nameFromZToA: 'Nome dalla Z alla A',
        surnameFromZToA: 'Cognome dalla Z alla A',
        olderToNewest: 'Ordine: dai meno recenti ai più recenti',
        newerToOlder: 'Ordine: dai più recenti ai meno recenti'
      },
      icons: {
        name: 'N',
        surname: 'C'
      },
      filters: {
        title: 'Filtri',
        labels: {
          resetFilters: 'Reset',
          resetSorting: 'Reset',
          registrationDate: 'Data di registrazione',
          sorting: 'Ordinamento',
          search: 'Cerca',
          club: 'Società',
        },
        placeholders: {
          club: 'Cerca una società...',
          all: 'Cerca...'
        }
      },
      counters: {
        results: 'Risultati:'
      }
    },
    form: {
      labels: {
        name: 'Nome',
        surname: 'Cognome',
        taxcode: 'Codice fiscale',
        email: 'E-mail',
        password: 'Password',
        'password-confirm': 'Conferma la password',
        street: 'Indirizzo',
        streetNumber: 'n. civico',
        district: 'Comune',
        zipcode: 'CAP',
        state: 'Provincia',
        birthDate: 'Data di nascita',
        birthPlace: 'Luogo di nascita',
      },
      placeholders: {
        name: 'Inserisci il nome',
        surname: 'Inserisci il cognome',
        taxcode: 'Inserisci il codice fiscale',
        email: 'Inserisci e-mail',
        password: 'Inserisci la password',
        'password-confirm': 'Conferma la password',
        street: 'Inserisci l\'indirizzo',
        streetNumber: 'Inserisci il n. civico',
        district: 'Inserisci il comune',
        zipcode: 'Inserisci il CAP',
        state: 'Inserisci la provincia',
        birthDate: 'Inserisci la data di nascita',
        birthPlace: 'Inserisci luogo di nascita',
      },
      actions: {
        create: 'Aggiungi utente',
        edit: 'Modifica utente',
      }
    },
    notifications: {
      edit: {
        title: 'Modifica utente',
        message: 'Utente <b>{{fullname}}</b> modificato con successo',
        error: 'Errore durante la modifica'
      },
      create: {
        title: 'Crea utente',
        message: 'Utente <b>{{fullname}}</b> creato con successo',
        error: 'Errore durante la creazione'
      },
      import: {
        title: 'Import utenti',
        message: 'Import effettuato con successo',
        errors: {
          title: 'Errore durante l\'import',
          oversize: 'File troppo grande. La grandezza del file non può superate i 2Mb'
        }
      },
      export: {
        title: 'Export utenti',
        message: 'Export effettuato con successo',
        errors: 'Errore durante l\'export',
      },
      delete: {
        title: 'Cancella utente',
        message: 'Utente <b>{{fullName}}</b> cancellato con successo',
        error: 'Errore durante la cancellazione'
      }
    },
    modals: {
      delete: {
        title: 'Cancella utente',
        message: 'Stai per cancellare l\'utente <b>{{fullName}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      },
      export: {
        title: 'Esporta utenti',
        select: 'Seleziona i campi da esportare',
        labels: {
          name: 'Nome',
          surname: 'Cognome',
          taxCode: 'Codice fiscale',
          email: 'E-mail',
          birthDate: 'Data di nascita',
          birthPlace: 'Luogo di nascita',
          street: 'Indirizzo',
          streetNumber: 'N. civico',
          zipcode: 'CAP',
          district: 'Comune',
          state: 'Provincia',
          registrationDate: 'Data di registrazione',
          club: 'Società'
        },
        buttons: {
          export: 'Esporta',
        }
      }
    }
  },
  partners: {
    title: 'Partners',
    create: 'Aggiungi',
    edit: 'Modifica',
    upload: 'Carica',
    list: {
      none: 'Nessun partners registrato.',
      labels: {
        accountStatus: {
          label: 'Account',
          PENDING: 'In attesa',
          CONFIRMED: 'Confermato'
        },
        businessName: 'Ragione Sociale',
        contactPerson: 'Referente',
        vatcode: 'P.Iva/Codice Fiscale',
        email: 'E-mail',
        website: 'Sito web',
        street: 'Indirizzo',
        phone: 'Telefono',
        description: 'Descrizione',
        actions: 'Azioni'
      },
      filters: {
        title: 'Filtri',
        labels: {
          resetFilters: 'Reset',
          registrationDate: 'Data di registrazione',
          rank: 'Rank',
          selectRank: 'Seleziona il rank',
          search: 'Cerca',
        }
      },
      counters: {
        results: 'Risultati:'
      }
    },
    form: {
      labels: {
        rank: 'Rank',
        businessName: 'Ragione sociale',
        description: 'Descrizione',
        vatcode: 'P.Iva/Codice Fiscale',
        website: 'Sito web',
        video: 'Video',
        contactPerson: 'Referente',
        phone: 'Telefono',
        email: 'E-mail',
        street: 'Indirizzo',
        streetNumber: 'n. civico',
        district: 'Comune',
        zipcode: 'CAP',
        state: 'Provincia',
      },
      placeholders: {
        rank: 'Scegli il rank',
        businessName: 'Inserisci la ragione sociale',
        description: 'Inserisci una descrizione',
        vatcode: 'Inserisci la p.iva/codice fiscale',
        website: 'Inserisci sito web',
        video: 'Carica un video',
        contactPerson: 'Inserisci referente',
        phone: 'Inserisci telefono',
        email: 'Inserisci e-mail',
        street: 'Indirizzo',
        streetNumber: 'n. civico',
        district: 'Comune',
        zipcode: 'CAP',
        state: 'Provincia',
      },
      actions: {
        create: 'Aggiungi partner',
        edit: 'Modifica partner'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica partners',
        message: 'Partner <b>{{businessName}}</b> modificato con successo',
        error: 'Errore durante la modifica'
      },
      create: {
        title: 'Crea partners',
        message: 'Partner <b>{{businessName}}</b> creato con successo',
        error: 'Errore durante la creazione'
      },
      delete: {
        title: 'Cancella partners',
        message: 'Partner <b>{{businessName}}</b> cancellato con successo',
        error: 'Errore durante la cancellazione'
      },
      upload: {
        logo: {
          title: 'Carica logo',
          message: 'Logo caricato con successo',
          errors: {
            title: 'Errore nella caricazione',
            oversize: 'File troppo grande. Limite massimo consentito 2Mb'
          }
        }
      }
    },
    modals: {
      delete: {
        title: 'Cancella partner',
        message: 'Stai per cancellare il partner <b>{{businessName}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      },
      loginAs : {
        title: 'Cambia utente',
        message: 'Vuoi loggarti come <b>{{partnerName}}</b>?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    },
  },
  events: {
    title: 'Eventi',
    create: 'Aggiungi',
    edit: 'Modifica',
    upload: 'Carica',
    list: {
      none: 'Nessun evento.',
      labels: {
        title: 'Titolo',
        description: 'Descrizione',
        startDate: 'Data d\'inizio',
        endDate: 'Data di fine',
        status: {
          PENDING: 'In attesa',
          APPROVED: 'Confermato',
          REJECTED: 'Rifiutato'
        },
        changeStatus: {
          pending: 'Metti in attesa',
          approved: 'Conferma',
          rejected: 'Rifiuta'
        }
      },
      filters: {
        title: 'Filtri',
        labels: {
          resetFilters: 'Reset',
          status: {
            label: 'Stato',
            values: {
              PENDING: 'In attesa',
              APPROVED: 'Confermato',
              REJECTED: 'Rifiutato'
            },
            placeholder: 'Seleziona una stato'
          }
        }
      },
    },
    form: {
      labels: {
        title: 'Titolo',
        description: 'Descrizione',
        startDate: 'Data d\'inizio',
        img: 'Immagine',
        endDate: 'Data di fine',
      },
      placeholders: {
        title: 'Inserisci il titolo',
        description: 'Inserisci una descrizione',
        startDate: 'Inserisci la data d\'inizio',
        endDate: 'Inserisci data di fine',
        img: 'Carica immagine',
      },
      actions: {
        create: 'Aggiungi evento',
        edit: 'Modifica evento',
      }
    },
    modals: {
      delete: {
        title: 'Cancella evento',
        message: 'Stai per cancellare l\'evento <b>{{title}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    },
    notifications: {
      edit: {
        title: 'Modifica evento',
        message: 'Evento <b>{{title}}</b> modificato con successo',
        error: 'Errore durante la modifica'
      },
      create: {
        title: 'Crea evento',
        message: 'Evento <b>{{title}}</b> creato con successo',
        error: 'Errore durante la creazione'
      },
      delete: {
        title: 'Cancella evento',
        message: 'Evento <b>{{title}}</b> cancellato con successo',
        error: 'Errore durante la cancellazione'
      },
      upload: {
        title: 'Upload immagine',
        message: 'Immagine caricata con successo',
        errors: {
          title: 'Errore durante il caricamento',
          oversize: 'File troppo grande. Massima dimensione consentita 2Mb'
        }
      }
    },
  },
  coupon: {
    title: 'Coupon',
    create: 'Aggiungi',
    edit: 'Modifica',
    upload: 'sfoglia',
    list: {
      none: 'Nessun coupon.',
      labels: {
        title: 'Titolo',
        status: 'Stato',
        startDate: 'Inizio data di scadenza',
        endDate: 'Fine data di scadenza',
        file: 'File',
        description: 'Descrizione',
        actions: 'Azioni',
        partner: 'Partner',
        registrationDate: 'Data di creazione',
      },
      filters: {
        title: 'Filtri',
        labels: {
          resetFilters: 'Reset',
          creationDate: 'Data di creazione',
          expirationDate: 'Data di scadenza',
          partner: 'Partner',
          selectPartner: 'Seleziona il partner',
          sorting: 'Ordinamento'
        }
      },
      counters: {
        results: 'Risultati:'
      }
    },
    form: {
      labels: {
        title: 'Titolo',
        description: 'Descrizione',
        startDate: 'Data d\'inizio',
        endDate: 'Data di fine',
        upload: 'Carica file pdf',
        partner: 'Partner'
      },
      placeholders: {
        title: 'Inserisci il titolo',
        startDate: 'Inserisci la data d\'inizio',
        endDate: 'Inserisci data di fine',
        description: 'Inserisci una descrizione',
        partner: 'Cerca un partner...'
      },
      actions: {
        create: 'Aggiungi coupon',
        edit: 'Modifica coupon'
      }
    },
    notifications: {
      edit: {
        title: 'Modifica coupon',
        message: 'Coupon <b>{{title}}</b> modificato con successo',
        error: 'Errore durante la modifica'
      },
      create: {
        title: 'Crea coupon',
        message: 'Coupon <b>{{title}}</b> creato con successo',
        error: 'Errore durante la creazione'
      },
      delete: {
        title: 'Cancella coupon',
        message: 'Coupon <b>{{title}}</b> cancellato con successo',
        error: 'Errore durante la cancellazione'
      },
      upload: {
        pdf: {
          title: 'Upload pdf',
          message: 'File caricato con successo',
          errors: {
            title: 'Errore durante il caricamento',
            oversize: 'File troppo grande. Massima dimensione consentita 2Mb'
          }
        }
      }
    },
    modals: {
      delete: {
        title: 'Cancella coupon',
        message: 'Stai per cancellare il coupon <b>{{title}}</b>.<br> Vuoi procedere?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    },
  },
  reporting: {
    title: 'Reportistica',
    list: {
      none: 'Nessun coupon scaricato',
      labels: {
        partner: 'Partner',
        coupon: 'Coupon',
        period: 'Periodo',
        downloads: 'Downloads',
        downloadDate: 'Data di scaricamento',
        state: 'Provincia',
        club: 'Società',
      },
      filters: {
        title: 'Filtri',
        labels: {
          startDate: 'Data d\'inizio*',
          endDate: 'Data di fine*',
          club: 'Società',
          state: 'Provincia',
          resetFilters:'Reset',
          partner: 'Partner',
          nameCoupon: 'Nome del coupon',
          nameUser: 'Nome dell\'utente'
        },
        placeholders: {
          club: 'Cerca una società...',
          state: 'Scegli una provincia',
          partner: 'Cerca un partner...',
          user: 'Cerca un utente...',
          coupon: 'Cerca un coupon...'
        }
      },
      counters: {
        results: 'Risultati:'
      }
    },
  },
  contacts: {
    title: 'Contatti',
    export: 'Esporta',
    list: {
      none: 'Nessun contatto',
      labels: {
        fullName: 'Nome completo',
        email: 'Email',
        subject: 'Oggetto',
        message: 'Messaggio',
        sendingDate: 'Data di invio',
        marketing: 'Marketing',
        type: {
          title: 'Tipo',
          supportRequest: 'Supporto',
          eventSponsorshipRequest: 'Sponsorizzazione'
        }
      },
      filters: {
        title: 'Filtri',
        labels: {
          search: 'Cerca',
          sendingDate: 'Data di invio',
          type: {
            title: 'Tipo',
            supportRequest: 'Supporto',
            eventSponsorshipRequest: 'Sponsorizzazione'
          },
          marketing: {
            title: 'Marketing',
            yes: 'Si',
            no: 'No'
          },
          resetFilters: 'Reset',
          resetSorting: 'Reset',
          sorting: 'Ordinamento'
        },
        placeholders: {
          search: 'Cerca...'
        },
      },
      counters: {
        results: 'Risultati:'
      },
      expanded: {
        show: 'Mostra',
        hide: 'Nascondi'
      },
      marketing: {
        yes: 'Si',
        no: 'No'
      }
    },
    modals: {
      export: {
        title: 'Esporta contatti',
        message: 'Vuoi esportare i contatti?',
        buttons: {
          confirm: 'Si',
          dismiss: 'No'
        }
      }
    }
  }
}
